.playerBadge {
    display: flex;
    max-width: 22.5rem;
    width: 100%;
    margin-right: 2rem;
}

.playerBadge .name {
    font-size: 1.15rem;
}

.playerBadge .description {
    color: #fff;
}

.playerBadge .season {
    font-size: 0.75rem;
    margin-top: -.25rem;
}

.tier1,
.tier2,
.tier3 {
    width: 3.5rem;
    height: 3.5rem;
    margin-right: 1rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tier1 img,
.tier2 img,
.tier3 img {
    width: 2rem;
}

.tier1 {
    background-color: #FF5555;
}

.tier2 {
    background-color: #AAAAAA;
}

.tier3 {
    background-color: #FFAA00;
}