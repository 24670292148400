.themeButton {
    width: 3rem;
    height: 3rem;
    background-color: rgba(1, 1, 16, .5);
    color: #fff;
    border-radius: .5rem;
    position: fixed;
    bottom: 1rem;
    left: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    cursor: pointer;
}