@import url('https://fonts.googleapis.com/css2?family=Crimson+Pro:wght@700&family=Poppins:wght@400;700&display=swap');

* {
    margin: 0;
    padding: 0;
}

html {
    font-size: 12px;
}

body {
    font-size: 1rem;
    font-family: "Poppins", Arial, Helvetica, sans-serif;
    background-color: #010110;
    color: #FFFFFF;
    padding-top: 7rem;
    max-width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-image: url("../public/images/mk_background_dark.webp");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}

.light {
    background-image: url("../public/images/mk_background_light.webp") !important;
}

@media screen and (min-width: 756px) {
    html {
        font-size: 14px;
    }
}

@media screen and (min-width: 1200px) {
    html {
        font-size: 15px;
    }
}