.kothTitle {
    font-weight: 500;
    font-size: 2.25rem;
    display: flex;
    align-items: center;
}

.title {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 1.75rem;
    margin-top: 2rem;
    margin-bottom: .75rem;
}

.title::after {
    content: '';
    display: block;
    height: .25rem;
    width: 4.5rem;
    background-color: #FFB90F;
}

.highlights {
    margin-top: 1.5rem;
    display: flex;
    flex-wrap: wrap;
}

.statistics {
    margin-top: 1.5rem;
}