.kothLogs {
    display: flex;
}

.kothLogs > div {
    height: calc(100vh - 7rem);
    padding: 2rem;
    box-sizing: border-box;
}

.kothLogs > div:first-child {
    width: 0;
    padding: 0;
    overflow: hidden;
    display: none;
}

.kothLogs > div:last-child {
    flex-grow: 1;
    background-color: rgba(1, 1, 16, .75);
    overflow-y: auto;
}

.kothLogs a {
    color: rgba(255, 255, 255, 0.5);
    text-decoration: none;
    margin-bottom: 0.5rem;
    display: inline-block;
}

@media screen and (min-width: 1200px) {
    .kothLogs > div {
        padding: 4rem;
    }

    .kothLogs > div:first-child {
        min-width: 35%;
        display: flex;
    }
}