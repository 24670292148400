nav {
    height: 7rem;
    box-sizing: border-box;
    display: flex;
    width: 100%;
    align-items: center;
    background-color: #010110;
    flex-wrap: wrap;
    position: fixed;
    top: 0;
    z-index: 200;
}

nav > a {
    height: 100%;
    display: inline-flex;
    align-items: center;
    flex-grow: 1;
    padding: 0 1rem;
    text-decoration: none;
}

nav > a img {
    height: 4rem;
}

nav > a span {
    font-size: 1.5rem;
    font-family: "Crimson Pro", "Times New Roman", Times, serif;
    color: #FFB90F;
    margin-left: 1rem;
}

nav > a span b {
    font-size: 2rem;
}

nav ul {
    max-height: 0;
    height: auto;
    overflow: hidden;
    list-style: none;
    transition: max-height 1s 0s;
    flex-basis: 100%;
}

nav ul li {
    box-sizing: border-box;
}

nav ul li a {
    color: #FFFFFF;
    text-decoration: none;
    display: block;
    padding: 1rem;
    background-color: rgba(1, 1, 16, 0.75);
}

nav ul li a:hover,
nav ul li a:focus, 
nav ul li a:active {
    background-color: rgba(1, 1, 16, 0.85);
}

nav ul li a span {
    margin-left: .5rem;
}

nav > i {
    font-size: 2rem;
    color: #FFFFFF;
    padding: 1rem;
    margin: 1rem;
    border-radius: 0.5rem;
    cursor: pointer;
}

@media screen and (min-width: 1200px) {
    nav {
        padding-left: calc(50% - 550px);
        padding-right: calc(50% - 550px);
    }

    nav > i {
        display: none !important;
    }

    nav ul {
        overflow: visible;
        list-style: none;
        flex-basis: auto;
        display: flex;
        max-height: none !important;
    }

    nav ul li a {
        margin-right: .5rem;
    }

    nav ul li a:hover,
    nav ul li a:focus,
    nav ul li a:active {
        color: rgba(255, 255, 255, 0.75);
    }
}