.playerName {
    font-weight: 500;
    font-size: 2.25rem;
    display: flex;
    align-items: center;
}

.playerName span {
    font-size: 1.25rem;
    margin-right: .5rem;
    color: #878787;
}

.online {
    color: #55FF55 !important;
}

.title {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 1.75rem;
    margin-top: 2rem;
    margin-bottom: .75rem;
}

.title::after {
    content: '';
    display: block;
    height: .25rem;
    width: 4.5rem;
    background-color: #FFB90F;
}

p {
    margin-bottom: 0.5rem;
    font-size: 1.05rem;
    color: rgba(255, 255, 255, .6);
}

p b,
p em {
    color: white;
}

.playerBadges {
    margin-top: 1.5rem;
    display: flex;
    flex-wrap: wrap;
}

.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.loading i {
    font-size: 2.5rem;
    margin-bottom: 0.5rem;
}