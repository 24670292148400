.playerSearchBox {
    width: 100%;
    background-color: rgba(1, 1, 16, 0.75);
    border-radius: 1rem;
    text-align: center;
}

.playerSearchBox h2 {
    font-size: 2rem;
    font-weight: 500;
    margin: 1rem auto;
}

.playerSearchBox input {
    width: 100%;
    padding: 1rem;
    box-sizing: border-box;
    text-align: center;
    font-size: 1.75rem;
    border: 0;
    background-color: rgba(255, 255, 255, 0.1);
    color: rgba(255, 255, 255, 0.5);
}

.playerSearchBox input::placeholder {
    color: rgba(255, 255, 255, 0.5);
}

.playerSearchBox .button {
    display: inline-block;
    background-color: #FFB90F;
    color: #010110;
    border-radius: 50rem;
    text-transform: uppercase;
    padding: 1rem 2rem; 
    border: 0;
    margin: 1.5rem auto;
    cursor: pointer;
    text-decoration: none;
}

input:focus {
    outline: 0;
}