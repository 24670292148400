.kothWinnerViewer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transform: translateY(-4rem);
}

.kothWinnerViewer img {
    min-width: 50%;
    max-width: 20rem;
}

.kothWinnerViewer {
    font-weight: bold;
    text-transform: uppercase;
}

.kothWinnerViewer div {
    font-size: 2rem;
    line-height: 1.25;
    -webkit-text-stroke: 1px solid black;
    text-shadow:
    -1px -1px 0 #000,
     1px -1px 0 #000,
    -1px  1px 0 #000,
     1px  1px 0 #000; /* Shadow offsets and color */
}

.kothWinnerViewer div:last-child {
    font-size: 2.75rem;
}