table {
    width: 100%;
    border-collapse: collapse;
    background-color: #010110;
  }
  
  th, td {
    padding: .5rem 1rem;
    text-align: left;
    border-bottom: 0.5px solid rgba(255, 255, 255, 0.25);
    width: 12.5%;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  
  th {
    cursor: pointer;
    user-select: none;
  }
  
  th span {
    font-size: 12px;
  }

  .playerColumn {
    display: flex;
  }

  .playerColumn img {
    height: 1.5rem;
    margin-right: .5rem;
  }