.warHighlight {
    display: flex;
    max-width: 18rem;
    width: 100%;
    margin-right: 2rem;
    margin-bottom: 1rem;
}

.warHighlight .name {
    font-size: 1.15rem;
}

.warHighlight .highlight {
    color: #fff;
}

.warHighlight img {
    width: 2rem;
}

.warHighlight div:first-child {
    width: 3.5rem;
    height: 3.5rem;
    margin-right: 1rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #070867;
}